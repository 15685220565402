import { ValueFormatterParams } from '@ag-grid-community/core';

import { CurrencyPipe, DictionaryPipe, JoinArrayPipe, NumberFormatPipe, PipeWrapper } from '@ess/shared/utils/pipes';

import { ISFormatter } from '../models';

export interface MetricsFormatters {
  integerFormatter: ISFormatter;
  floatFormatter: ISFormatter;
  percentFormatter: ISFormatter;
}

export class ISSharedCellFormattersHelper {
  static getMetricsFormatters(): MetricsFormatters {
    const numberFormatPipe = new NumberFormatPipe();
    const floatPipe = new PipeWrapper(numberFormatPipe, {
      minimumIntegerDigits: 1,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    const integerPipe = new PipeWrapper(numberFormatPipe, {
      minimumIntegerDigits: 1,
      maximumFractionDigits: 0,
    });
    const percentPipe = new PipeWrapper(numberFormatPipe, {
      minimumIntegerDigits: 1,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: 'percent',
    });

    return {
      floatFormatter: (params: ValueFormatterParams): string => floatPipe.transform(params.value),
      integerFormatter: (params: ValueFormatterParams): string => integerPipe.transform(params.value),
      percentFormatter: (params: ValueFormatterParams): string => percentPipe.transform(params.value),
    };
  }

  static getDictionaryFormatter(dictionary: { [key: string]: string }): ISFormatter {
    const dictionaryPipe = new PipeWrapper(new DictionaryPipe(), dictionary);
    return (params: ValueFormatterParams): string => dictionaryPipe.transform(params.value);
  }

  static getJoinArrayFormatter(separator = ' • '): ISFormatter {
    const joinArrayPipe = new PipeWrapper(new JoinArrayPipe(), separator);
    return (params: ValueFormatterParams): string => joinArrayPipe.transform(params.value);
  }

  static getCurrencyFormatter(currency: string): ISFormatter {
    const currencyPipe = new PipeWrapper(new CurrencyPipe(), currency);
    return (params: ValueFormatterParams): string => currencyPipe.transform(params.value);
  }
}
