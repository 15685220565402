import { ISMenuItemTypeEnum, ISUserRoleEnum } from './../enums';
import { ISMenu } from './../models';
import { ISRoutesConfig } from './is-routes.config';

import { ISSharedEnvironmentHelper } from '../helpers';

export class ISMenuConfig {
  static readonly appMenu = (): ISMenu => [
    { label: 'Clients', icon: 'clients', link: ISRoutesConfig.clients(), type: ISMenuItemTypeEnum.LINK },
  ];

  static readonly clientMenu = (clientId: string): ISMenu => [
    { label: 'Back to clients', link: ISRoutesConfig.clients(), type: ISMenuItemTypeEnum.BACK },
    { label: 'Projects', icon: 'projects', link: ISRoutesConfig.projects(clientId), type: ISMenuItemTypeEnum.LINK },
    { type: ISMenuItemTypeEnum.LINE },
    {
      label: 'Categories',
      icon: 'categories',
      link: ISRoutesConfig.categories(clientId),
      type: ISMenuItemTypeEnum.LINK,
    },
  ];

  static readonly projectMenu = (clientId: string, projectId: number): ISMenu => [
    { label: 'Back to projects', link: ISRoutesConfig.projects(clientId), type: ISMenuItemTypeEnum.BACK },
    {
      label: 'Channel Overlap',
      icon: 'tactic-co',
      link: ISRoutesConfig.channelOverlap(clientId, projectId),
      type: ISMenuItemTypeEnum.LINK,
    },
    {
      label: 'Search Gaps',
      icon: 'tactic-sg',
      link: ISRoutesConfig.searchGaps(clientId, projectId),
      type: ISMenuItemTypeEnum.LINK,
    },
    {
      label: 'Landing Page\nOptimisation',
      icon: 'tactic-lpo',
      link: ISRoutesConfig.landingPageOptimisation(clientId, projectId),
      type: ISMenuItemTypeEnum.LINK,
    },
    { type: ISMenuItemTypeEnum.LINE },
    {
      label: 'Integrated Search\nUniverse',
      icon: 'universe',
      link: ISRoutesConfig.universe(clientId, projectId),
      type: ISMenuItemTypeEnum.LINK,
    },
    {
      label: 'Project settings',
      icon: 'settings',
      link: ISRoutesConfig.projectSettings(clientId, projectId),
      type: ISMenuItemTypeEnum.LINK,
      roles: [ISUserRoleEnum.CLIENT_ADMIN, ISUserRoleEnum.SPECIALIST],
    },
  ];

  static readonly publicMenu = (environment: string): ISMenu => [
    { label: 'Privacy policy', icon: 'policy', link: ISRoutesConfig.privacyPolicy(), type: ISMenuItemTypeEnum.LINK },
    { label: 'Licenses', icon: 'license', link: ISRoutesConfig.licenses(), type: ISMenuItemTypeEnum.LINK },
    ...this.__librariesMenuItems(environment),
  ];

  private static readonly __librariesMenuItems = (env: string): ISMenu =>
    ISSharedEnvironmentHelper.isTestingEnvironment(env)
      ? [
          { type: ISMenuItemTypeEnum.LINE },
          {
            label: 'App library',
            icon: 'stylus_note',
            link: ISRoutesConfig.appLibrary(),
            type: ISMenuItemTypeEnum.LINK,
          },
          {
            label: 'Shared library',
            icon: 'brush',
            link: ISRoutesConfig.sharedLibrary(),
            type: ISMenuItemTypeEnum.LINK,
          },
        ]
      : [];
}
