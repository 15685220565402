import { ISMetricEnum } from '../enums';
import { ISMetricKey } from '../models';

export const IS_METRICS_LABELS: { [key in ISMetricKey]: string } = {
  [ISMetricEnum.CLICKS_COVERAGE]: 'Clicks\ncoverage',
  [ISMetricEnum.COMBINED_IMPRESSIONS]: 'Combined\nimpressions',
  [ISMetricEnum.MAX_IMPRESSIONS]: 'Max\nimpressions',
  [ISMetricEnum.CONVERSIONS_VALUE]: 'Conversions\nvalue',
  [ISMetricEnum.MONTHLY_SEARCHES]: 'Monthly searches',
  [ISMetricEnum.PAID_IMPRESSIONS_SHARE]: 'Paid\nimpressions sum share',
  [ISMetricEnum.MAX_PAID_IMPRESSIONS_SHARE]: 'Paid\nimpressions max share',
  [ISMetricEnum.PPC_CLICKS]: 'Clicks\n(PPC)',
  [ISMetricEnum.PPC_CONVERSION_RATE]: 'Conversions rate\n(PPC)',
  [ISMetricEnum.PPC_CONVERSIONS]: 'Conversions\n(PPC)',
  [ISMetricEnum.PPC_COST_SHARE]: 'Ad cost share',
  [ISMetricEnum.PPC_CPA]: 'Avg. CPA\n(PPC)',
  [ISMetricEnum.PPC_CPC]: 'Avg. CPC\n(PPC)',
  [ISMetricEnum.PPC_CTR]: 'CTR\n(PPC)',
  [ISMetricEnum.PPC_IMPRESSIONS]: 'Impressions\n(PPC)',
  [ISMetricEnum.PPC_ROAS]: 'ROAS\n(PPC)',
  [ISMetricEnum.PPC_SPEND]: 'Spend\n(PPC)',
  [ISMetricEnum.SEO_CLICKS]: 'Clicks\n(SEO)',
  [ISMetricEnum.SEO_CTR]: 'CTR\n(SEO)',
  [ISMetricEnum.SEO_IMPRESSIONS]: 'Impressions\n(SEO)',
  [ISMetricEnum.SEO_POSITION]: 'Avg. position\n(SEO)',
  // non-numeric
  [ISMetricEnum.COMPETITION]: 'Competition',
  [ISMetricEnum.PPC_LANDING_PAGE_QUALITY_SCORE]: 'Avg. LP Experience',
  [ISMetricEnum.SEARCH_TERM_DIFFICULTY]: 'Search term\ndifficulty',
};
