import { ValidationErrors } from '@angular/forms';

import { SharedTreeElement } from '@ess/shared/utils/models';

import { ISAccountStatusEnum } from '../enums';
import { ISAccountStatus, ISGoogleAdsAccount, ISProjectGAdsAccount, ISProjectGSCAccount } from '../models';

export class ISSharedAccountsHelper {
  static reduceGoogleAdsAccountsToTree(accounts: ISGoogleAdsAccount[]): SharedTreeElement[] {
    const map: { [key: string]: SharedTreeElement } = {};
    const sorted: ISGoogleAdsAccount[] = this.__getSortedAccountsArray(accounts);

    return sorted.reduce<SharedTreeElement[]>((acc: SharedTreeElement[], current: ISGoogleAdsAccount) => {
      const { account_id, name, parent_id } = current;

      const parent = parent_id ? map[parent_id] : null;
      const parentPath = parent ? parent.path + ' / ' : '';

      const treeElement: SharedTreeElement = {
        label: name,
        id: account_id,
        path: parentPath + name,
        children: [],
      };

      map[account_id] = treeElement;

      parent ? parent.children.push(treeElement) : acc.push(treeElement);

      return acc;
    }, []);
  }

  static gscAccountsErrors(
    value: string[] | null | undefined,
    accounts: ISProjectGSCAccount[],
  ): ValidationErrors | null {
    return this.__accountsErrors(
      value,
      accounts.map((a) => ({ id: a.property, status: a.status })),
    );
  }

  static gadsAccountsErrors(
    value: string[] | null | undefined,
    accounts: ISProjectGAdsAccount[],
  ): ValidationErrors | null {
    return this.__accountsErrors(
      value,
      accounts.map((a) => ({
        id: a.account_id,
        status: a.status,
      })),
    );
  }

  private static __accountsErrors(
    value: string[] | null | undefined,
    accounts: ({
      id: string;
    } & ISAccountStatus)[],
  ): ValidationErrors | null {
    if (value === null || value === undefined || !Array.isArray(value) || value.length === 0) {
      return null;
    }

    const invalidAccounts: Map<string, ISAccountStatusEnum> = accounts.reduce<Map<string, ISAccountStatusEnum>>(
      (acc: Map<string, ISAccountStatusEnum>, account) => {
        if (value.includes(account.id) && account.status !== ISAccountStatusEnum.VALID) {
          acc.set(account.id, account.status);
        }
        return acc;
      },
      new Map<string, ISAccountStatusEnum>(),
    );

    return invalidAccounts.size === 0 ? null : { invalidAccounts };
  }

  private static __getSortedAccountsArray(accounts: ISGoogleAdsAccount[]): ISGoogleAdsAccount[] {
    return [...accounts].sort((a, b) => {
      // Sort by null parent_id first
      if (a.parent_id === null && b.parent_id !== null) {
        return -1;
      } else if (a.parent_id !== null && b.parent_id === null) {
        return 1;
      } else if (a.parent_id === null && b.parent_id === null) {
        // If both have null parent_id, sort alphabetically by account_id
        return a.account_id.localeCompare(b.account_id);
      } else {
        // Sort by parent_id
        const parentComparison = a.parent_id!.localeCompare(b.parent_id!);
        if (parentComparison !== 0) {
          return parentComparison;
        } else {
          // If parent_id is the same, sort alphabetically by account_id
          return a.account_id.localeCompare(b.account_id);
        }
      }
    });
  }
}
