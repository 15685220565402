import { ISAccountStatusEnum } from '../enums';

export const IS_ACCOUNTS_STATUS_MESSAGE: { [key in ISAccountStatusEnum]?: string } = {
  [ISAccountStatusEnum.FAILED]:
    'There is an issue with your account: OCTRA failed processing. Please raise an issue with OCTRA Customer Success team support.octra@groupm.com.',
  [ISAccountStatusEnum.DISABLED]:
    'There is an issue with your account: it was disabled by Google. Please re-authorize your account in OCTRA.',
  [ISAccountStatusEnum.DISCONNECTED]:
    'There is an issue with your account: account was disconnected in OCTRA. Please connect another account in OCTRA or raise and issue with OCTRA Customer Success team support.octra@groupm.com.',
};
