import { IS_METRICS_LABELS } from './is-metrics-labels.const';

import { ISMetricEnum } from '../enums';
import { ISMetricsGlossary } from '../models';

export const IS_METRICS_GLOSSARY: ISMetricsGlossary = [
  {
    metricName: IS_METRICS_LABELS[ISMetricEnum.PPC_CPC],
    tableContext:
      'The sum of money spent on one search term in a given time period divided by the sum of PPC clicks on the search term in that time period. This calculation is shown in each cell of the table.',
    chartContext:
      'The sum of money spent on filtered search terms for a given day divided by the sum of PPC clicks on the filtered search terms on the same day. This calculation is shown for each day on the chart.',
  },
  {
    metricName: IS_METRICS_LABELS[ISMetricEnum.PPC_CPA],
    tableContext:
      'The sum of money spent on one search term in a given time period divided by the sum of conversions for the search term in that time period. This calculation is shown in each cell of the table.',
    chartContext:
      'The sum of money spent on filtered search terms for a given day divided by the sum of conversions for the filtered search terms on the same day. This calculation is shown for each day on the chart.',
  },
  {
    metricName: IS_METRICS_LABELS[ISMetricEnum.SEO_POSITION],
    tableContext:
      'The average position of one search term in a given time period. This is the average of daily average positions. This calculation is shown in each cell of the table.',
    chartContext:
      'The average position of filtered search terms for each day of a given time period. This calculation is shown for each day on the chart.',
  },
  {
    metricName: IS_METRICS_LABELS[ISMetricEnum.PPC_CLICKS],
    tableContext:
      'The amount of PPC clicks on one search term in a given time period. This calculation is shown in each cell of the table.',
    chartContext:
      'The sum of PPC clicks on filtered search terms for each day of a given time period. This calculation is shown for each day on the chart.',
  },
  {
    metricName: IS_METRICS_LABELS[ISMetricEnum.SEO_CLICKS],
    tableContext:
      'The amount of SEO clicks on one search term in a given time period. This calculation is shown in each cell of the table.',
    chartContext:
      'The sum of SEO clicks on filtered search terms for each day of a given time period. This calculation is shown for each day on the chart.',
  },
  {
    metricName: IS_METRICS_LABELS[ISMetricEnum.COMBINED_IMPRESSIONS],
    tableContext:
      'The sum of PPC and SEO impressions of one search term in a given time period. This calculation is shown in each cell of the table.',
    chartContext:
      'The sum of PPC and SEO impressions of filtered search terms for each day of a given time period. This calculation is shown for each day on the chart.',
  },
  {
    metricName: IS_METRICS_LABELS[ISMetricEnum.PAID_IMPRESSIONS_SHARE],
    tableContext:
      'The sum of PPC impressions on one search term in a given time period divided by the sum of Combined Impressions of the search term in that time period. This calculation is shown in each cell of the table.',
    chartContext:
      'The sum of PPC impressions on filtered search terms for a given day divided by the sum of Combined Impressions of the filtered search terms on the same day. This calculation is shown for each day on the chart.',
  },
  {
    metricName: IS_METRICS_LABELS[ISMetricEnum.MAX_IMPRESSIONS],
    tableContext:
      'The maximum of PPC and SEO impressions of one search term in a given time period. This calculation is shown in each cell of the table.',
    chartContext:
      'The maximum of PPC and SEO impressions of filtered search terms for each day of a given time period. This calculation is shown for each day on the chart.',
  },
  {
    metricName: IS_METRICS_LABELS[ISMetricEnum.MAX_PAID_IMPRESSIONS_SHARE],
    tableContext:
      'The sum of PPC impressions on one search term in a given time period divided by the sum of Max Impressions of the search term in that time period. This calculation is shown in each cell of the table.',
    chartContext:
      'The sum of PPC impressions on filtered search terms for a given day divided by the sum of Max Impressions of the filtered search terms on the same day. This calculation is shown for each day on the chart.',
  },
  {
    metricName: IS_METRICS_LABELS[ISMetricEnum.CONVERSIONS_VALUE],
    tableContext:
      'The sum of conversions value of one search term in a given time period. This calculation is shown in each cell of the table.',
    chartContext:
      'The sum of conversions value of filtered search terms for each day of a given time period. This calculation is shown for each day on the chart.',
  },
  {
    metricName: IS_METRICS_LABELS[ISMetricEnum.PPC_CONVERSION_RATE],
    tableContext:
      'The sum of conversions of one search term from a given time period divided by the sum of PPC clicks on the search term from the same period. This calculation is shown in each cell of the table.',
    chartContext:
      'The sum of conversions of filtered search terms for a given day divided by the sum of PPC clicks on the filtered search terms on the same day. This calculation is shown for each day on the chart.',
  },
  {
    metricName: IS_METRICS_LABELS[ISMetricEnum.PPC_CONVERSIONS],
    tableContext:
      'The amount of paid conversions for one search term in a given time period. This calculation is shown in each cell of the table.',
    chartContext:
      'The sum of paid conversions for filtered search terms for each day of a given time period. This calculation is shown for each day on the chart.',
  },
  {
    metricName: IS_METRICS_LABELS[ISMetricEnum.PPC_CTR],
    tableContext:
      'The sum of PPC clicks on one search term in a given time period divided by the sum of PPC impressions of the search term in that time period. This calculation is shown in each cell of the table.',
    chartContext:
      'The sum of PPC clicks on filtered search terms for a given day divided by the sum of PPC impressions of the filtered search terms on the same day. This calculation is shown for each day on the chart.',
  },
  {
    metricName: IS_METRICS_LABELS[ISMetricEnum.SEO_CTR],
    tableContext:
      'The sum of SEO clicks on one search term in a given time period divided by sum of impressions of the search term in that time period. This calculation is shown in each cell of the table.',
    chartContext:
      'The sum of SEO clicks on filtered search terms for a given day divided by the sum of impressions of the search terms on the same day. This calculation is shown for each day on the chart.',
  },
  {
    metricName: IS_METRICS_LABELS[ISMetricEnum.PPC_IMPRESSIONS],
    tableContext:
      'The amount of PPC impressions of one search term in a given time period. This calculation is shown in each cell of the table.',
    chartContext:
      'The sum of PPC impressions of filtered search terms for each day of a given time period. This calculation is shown for each day on the chart.',
  },
  {
    metricName: IS_METRICS_LABELS[ISMetricEnum.SEO_IMPRESSIONS],
    tableContext:
      'The amount of SEO impressions of one search term in a given time period. This calculation is shown in each cell of the table.',
    chartContext:
      'The sum of SEO impressions of filtered search terms for each day of a given time period. This calculation is shown for each day on the chart.',
  },
  {
    metricName: IS_METRICS_LABELS[ISMetricEnum.PPC_ROAS],
    tableContext:
      'The sum of conversion values of one search term in a given time period divided by the money Spend on the search term in that time period. This calculation is shown in each cell of the table.',
    chartContext:
      'The sum of conversion values of filtered search terms for a given day divided by the money Spend on the filtered search terms on the same day. This calculation is shown for each day on the chart.',
  },
  {
    metricName: IS_METRICS_LABELS[ISMetricEnum.PPC_SPEND],
    tableContext:
      'Amount of money spent on one search term in a given time period. This calculation is shown in each cell of the table.',
    chartContext:
      'Sum of money spent on filtered search terms for each day of a given time period. This calculation is shown for each day on the chart.',
  },
  /* eslint-disable max-len */
  // {
  //   metricName: IS_METRICS_LABELS[ISMetricEnum.CLICKS_COVERAGE],
  //   tableContext:
  //     'The sum of SEO clicks and PPC clicks for 1 ST from a given time period divided by monthly searches from last available month. This calculation is shown in each cell of the table',
  //   chartContext:
  //     'The sum of SEO clicks and PPC clicks for filtered search terms for a given day divided by monthly searches from last available month. This calculation is shown for each day on the chart.',
  // },
  {
    metricName: IS_METRICS_LABELS[ISMetricEnum.COMPETITION],
    tableContext:
      'Competition value returned by Google Keyword Planner for given keyword. Google does not provide historic values of it so it is always for present day.',
  },
  {
    metricName: IS_METRICS_LABELS[ISMetricEnum.SEARCH_TERM_DIFFICULTY],
    tableContext:
      'Sum of weights assigned to 3 factors for given search term in given time period. Factor 1 - Monthly Searches (0.2 for Bottom 25% of , 0.5 for 25-75% MS range, 0.8 for rest). Factor 2 - Competition (0.2 for low, 0.5 for medium, 0.8 for high). Factor 3 - ST word count (0.2 for more than 6 words, 0.5 for 4-5 words, 0.8 for up to 3 words). Sum of those factor is in 0.8-2.4 range. That range is split into named parts: Low (<1.0), Medium (1.0> <2.0), High (>2.0).',
  },
  {
    metricName: IS_METRICS_LABELS[ISMetricEnum.MONTHLY_SEARCHES],
    tableContext:
      'Last available Monthly Searches value from Google Search Console. This value can be provided  by Google with a few days lag after month’s end. Monthly searches is number of searches for a specific keyword or phrase in a given month.',
  },
  {
    metricName: IS_METRICS_LABELS[ISMetricEnum.PPC_LANDING_PAGE_QUALITY_SCORE],
    tableContext:
      'Quality of landing page experience in context of given keyword in given time range as provided by Google.',
  },
  {
    metricName: IS_METRICS_LABELS[ISMetricEnum.PPC_COST_SHARE],
    tableContext:
      'The sum of money spent on a given search term in a given time period divided by the sum of money spent on all search terms in that time period. This calculation is shown in each cell of the table.',
    chartContext:
      'The sum of money spent on filtered search terms for a given day divided by the sum of money spent on the filtered search terms on the same day. This calculation is shown for each day on the chart.',
  },
];
