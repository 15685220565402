import { SharedFormOption } from '@ess/shared/utils/models';

import { ISCategory, ISCategoryDetails } from '../models';

export const isSharedCategoryOptionsHelper = (
  categories: ISCategory[] | ISCategoryDetails[] | null,
): SharedFormOption<number>[] => {
  return categories
    ? categories.map<SharedFormOption<number>>((category) => ({
        name: category.title,
        value: category.category_id,
        ...(category.description && { tooltip: category.description }),
      }))
    : [];
};
