export class ISRoutesParts {
  static readonly sharedLibrary: string = 'shared-library';
  static readonly appLibrary: string = 'app-library';
  static readonly public: string = 'public';
  static readonly privacyPolicy: string = 'privacy-policy';
  static readonly login: string = 'login';
  static readonly clients: string = 'clients';
  static readonly projects: string = 'projects';
  static readonly categories: string = 'categories';
  static readonly tactics: string = 'tactics';
  static readonly channelOverlap: string = 'channel-overlap';
  static readonly landingPageOptimisation: string = 'landing-page-optimisation';
  static readonly searchGaps: string = 'search-gaps';
  static readonly settings: string = 'settings';
  static readonly universe: string = 'universe';
  static readonly keywords: string = 'keywords';
  static readonly searchTerms: string = 'search-terms';
  static readonly urls: string = 'landing-pages';
  static readonly licenses: string = 'licenses';
}

export class ISRoutesParamsConfig {
  static readonly clientId: string = 'slug';
  static readonly projectId: string = 'projectId';
  static readonly categoryId: string = 'categoryId';
}

export class ISRoutesConfig {
  static readonly sharedLibrary = (): string[] => ['/', ISRoutesParts.public, ISRoutesParts.sharedLibrary];
  static readonly appLibrary = (): string[] => ['/', ISRoutesParts.public, ISRoutesParts.appLibrary];
  static readonly privacyPolicy = (): string[] => ['/', ISRoutesParts.public, ISRoutesParts.privacyPolicy];
  static readonly licenses = (): string[] => ['/', ISRoutesParts.public, ISRoutesParts.licenses];
  static readonly login = (): string[] => ['/', ISRoutesParts.login];
  static readonly clients = (): string[] => ['/', ISRoutesParts.clients];
  static readonly projects = (clientId: string): string[] => [
    '/',
    ISRoutesParts.clients,
    clientId,
    ISRoutesParts.projects,
  ];
  static readonly categories = (clientId: string): string[] => [
    '/',
    ISRoutesParts.clients,
    clientId,
    ISRoutesParts.categories,
  ];
  static readonly tactics = (clientId: string, projectId: number | string): string[] => [
    '/',
    ISRoutesParts.clients,
    clientId,
    ISRoutesParts.projects,
    `${projectId}`,
    ISRoutesParts.tactics,
  ];
  static readonly channelOverlap = (clientId: string, projectId: number | string): string[] => [
    ...ISRoutesConfig.tactics(clientId, projectId),
    ISRoutesParts.channelOverlap,
  ];
  static readonly landingPageOptimisation = (clientId: string, projectId: number | string): string[] => [
    ...ISRoutesConfig.tactics(clientId, projectId),
    ISRoutesParts.landingPageOptimisation,
  ];
  static readonly searchGaps = (clientId: string, projectId: number | string): string[] => [
    ...ISRoutesConfig.tactics(clientId, projectId),
    ISRoutesParts.searchGaps,
  ];
  static readonly universe = (clientId: string, projectId: number): string[] => [
    '/',
    ISRoutesParts.clients,
    clientId,
    ISRoutesParts.projects,
    projectId.toString(),
    ISRoutesParts.universe,
  ];
  static readonly searchTerms = (clientId: string, projectId: number): string[] => [
    ...this.universe(clientId, projectId),
    ISRoutesParts.searchTerms,
  ];
  static readonly urls = (clientId: string, projectId: number): string[] => [
    ...this.universe(clientId, projectId),
    ISRoutesParts.urls,
  ];
  static readonly projectSettings = (clientId: string, projectId: number | string): string[] => [
    '/',
    ISRoutesParts.clients,
    clientId,
    ISRoutesParts.projects,
    `${projectId}`,
    ISRoutesParts.settings,
  ];
  static readonly project = (clientId: string, projectId: number | string): string[] => [
    '/',
    ISRoutesParts.clients,
    clientId,
    ISRoutesParts.projects,
    `${projectId}`,
  ];
  static readonly category = (clientId: string, categoryId: number | string): string[] => [
    '/',
    ISRoutesParts.clients,
    clientId,
    ISRoutesParts.categories,
    `${categoryId}`,
  ];
}
