import { SharedListResponse } from '@ess/shared/utils/models';

import { ISProjectBase } from './is-project.model';

export interface ISCategory {
  category_id: number;
  title: string;
  last_updated: string;
  description: string | null;
  active: boolean;
}

export interface ISCategoryListItem extends ISCategory {
  values: string[];
  assigned_project_names: string[];
}

export interface ISCategoryValue {
  category_value_id?: number;
  value: string;
}

export interface ISCategoryDetails extends ISCategory {
  assigned_projects: ISProjectBase[];
  values: ISCategoryValue[];
}

export interface ISCategoriesFilters {
  query: string;
}

export type ISCategoriesForProject = Pick<SharedListResponse<ISCategoryDetails>, 'items'>;

type ISCategoryRequestBase = Pick<ISCategory, 'title' | 'description'> & {
  assigned_project_ids: number[];
};

export interface ISCategoryCreateRequest extends ISCategoryRequestBase {
  client_slug: string;
  values: string[];
}

export interface ISCategoryUpdateRequest extends ISCategoryRequestBase {
  values: ISCategoryValue[];
}

export type ISAssignedCategory = Pick<ISCategoryDetails, 'category_id' | 'title' | 'values'>;

export type ISCategorySearch = Pick<ISCategoryListItem, 'title' | 'values'>;

export interface ISCategorySearchMatchRequest {
  project_id: number;
  categories: ISCategorySearch[];
}

export type ISCategoryMatch = ISCategorySearch & { existing_categories: ISCategoryDetails[] };

export interface ISCategorySearchMatchResponse {
  categories: ISCategoryMatch[];
}

export enum ISCategoriesFormFieldsEnum {
  VALUES = 'category_value_ids',
}
