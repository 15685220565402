import { ISMetricEnum, ISMetricUnitEnum } from '../enums';
import { ISNumericMetricKey } from '../models';

export const IS_METRICS_UNITS: { [key in ISNumericMetricKey]: ISMetricUnitEnum } = {
  [ISMetricEnum.CLICKS_COVERAGE]: ISMetricUnitEnum.PERCENTAGE,
  [ISMetricEnum.COMBINED_IMPRESSIONS]: ISMetricUnitEnum.INTEGER,
  [ISMetricEnum.MAX_IMPRESSIONS]: ISMetricUnitEnum.INTEGER,
  [ISMetricEnum.CONVERSIONS_VALUE]: ISMetricUnitEnum.CURRENCY,
  [ISMetricEnum.MONTHLY_SEARCHES]: ISMetricUnitEnum.INTEGER,
  [ISMetricEnum.PAID_IMPRESSIONS_SHARE]: ISMetricUnitEnum.PERCENTAGE,
  [ISMetricEnum.MAX_PAID_IMPRESSIONS_SHARE]: ISMetricUnitEnum.PERCENTAGE,
  [ISMetricEnum.PPC_CLICKS]: ISMetricUnitEnum.INTEGER,
  [ISMetricEnum.PPC_CONVERSION_RATE]: ISMetricUnitEnum.PERCENTAGE,
  [ISMetricEnum.PPC_CONVERSIONS]: ISMetricUnitEnum.INTEGER,
  [ISMetricEnum.PPC_COST_SHARE]: ISMetricUnitEnum.PERCENTAGE,
  [ISMetricEnum.PPC_CPA]: ISMetricUnitEnum.CURRENCY,
  [ISMetricEnum.PPC_CPC]: ISMetricUnitEnum.CURRENCY,
  [ISMetricEnum.PPC_CTR]: ISMetricUnitEnum.PERCENTAGE,
  [ISMetricEnum.PPC_IMPRESSIONS]: ISMetricUnitEnum.INTEGER,
  [ISMetricEnum.PPC_ROAS]: ISMetricUnitEnum.PERCENTAGE,
  [ISMetricEnum.PPC_SPEND]: ISMetricUnitEnum.CURRENCY,
  [ISMetricEnum.SEO_CLICKS]: ISMetricUnitEnum.INTEGER,
  [ISMetricEnum.SEO_CTR]: ISMetricUnitEnum.PERCENTAGE,
  [ISMetricEnum.SEO_IMPRESSIONS]: ISMetricUnitEnum.INTEGER,
  [ISMetricEnum.SEO_POSITION]: ISMetricUnitEnum.INTEGER,
};

export const IS_CURRENCY_METRICS: ISNumericMetricKey[] = Object.entries(IS_METRICS_UNITS)
  .filter(([_, unit]) => unit === ISMetricUnitEnum.CURRENCY)
  .map(([key, _]) => key as ISNumericMetricKey);
