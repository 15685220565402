import { ActivatedRouteSnapshot } from '@angular/router';

import { ISRoutesParamsConfig } from '../configs';

export class ISSharedProjectRouteHelper {
  static readonly getProjectCurrentRoute = (route: ActivatedRouteSnapshot): string[] => {
    let snapshot: ActivatedRouteSnapshot | null = route;
    const currentRoute: string[] = [];

    while (snapshot && !snapshot.paramMap.has(ISRoutesParamsConfig.projectId)) {
      snapshot = snapshot.firstChild;
    }
    snapshot = snapshot?.firstChild ?? null;
    while (snapshot) {
      if (snapshot.routeConfig?.path) {
        currentRoute.push(snapshot.routeConfig.path);
      }
      snapshot = snapshot.firstChild;
    }

    return currentRoute;
  };
}
