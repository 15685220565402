import { Subject } from 'rxjs';

import { SharedColDef, SharedGridCheckboxHeaderParams, SharedGridCheckboxHeaderState } from '@ess/shared/utils/models';

export class ISSharedColumnsHelper {
  static readonly checkboxColumn: (
    state$?: Subject<SharedGridCheckboxHeaderState>,
    selectAll?: (event: boolean) => void,
  ) => SharedColDef = (state$, selectAll) => ({
    colId: 'checkboxColumn',
    headerName: '',
    checkboxSelection: true,
    width: 40,
    minWidth: 40,
    maxWidth: 40,
    sortable: false,
    resizable: false,
    toggleEnabled: false,
    pinned: 'left' as const,
    lockPosition: 'left',
    headerComponent: 'checkboxHeader',
    ...(selectAll &&
      state$ && {
        headerComponentParams: {
          callback: (event: boolean) => selectAll(event),
          state$,
        } as SharedGridCheckboxHeaderParams,
      }),
  });

  static countryColumn = (): SharedColDef => {
    return {
      colId: 'countryColumn',
      headerName: 'Country',
      sortable: false,
      minWidth: 112,
      maxWidth: 112,
      cellRenderer: 'expandableCellRenderer',
      suppressMovable: false,
      autoHeight: true,
      toggleEnabled: false,
    };
  };

  static sourcesColumn = (): SharedColDef => {
    return {
      colId: 'sourcesColumn',
      headerName: '',
      cellRenderer: 'sourcesCellRenderer',
      pinned: 'left',
      minWidth: 44,
      maxWidth: 44,
      sortable: false,
      suppressMovable: false,
      autoHeight: true,
      toggleEnabled: false,
    };
  };

  static actionColumn = (): SharedColDef => {
    return {
      colId: 'actionColumn',
      headerName: '',
      sortable: false,
      width: 48,
      minWidth: 48,
      maxWidth: 48,
      resizable: false,
      pinned: 'right',
      lockPosition: 'right',
      cellClass: 'ess-shared-grid-cell--action',
      cellRenderer: 'actionCellRenderer',
      toggleEnabled: false,
    };
  };
}
