import { FormControl } from '@angular/forms';

import { SharedListResponse } from '@ess/shared/utils/models';

import { ISClient } from './is-client.model';
import { ISGoogleAdsAccount } from './is-google-ads-account.model';

import { ISAccountStatusEnum } from '../enums';

export enum ISProjectFormFieldsEnum {
  PROJECT_NAME = 'project_name',
  GSC_ACCOUNTS = 'google_search_console_accounts',
  GADS_ACCOUNTS = 'google_ads_accounts',
  DEFAULT_COUNTRY = 'default_country',
  CATEGORIES = 'categories_ids',
}

export interface ISProjectFormData {
  [ISProjectFormFieldsEnum.PROJECT_NAME]: string;
  [ISProjectFormFieldsEnum.GSC_ACCOUNTS]: string[];
  [ISProjectFormFieldsEnum.GADS_ACCOUNTS]: string[];
  [ISProjectFormFieldsEnum.DEFAULT_COUNTRY]: string;
  [ISProjectFormFieldsEnum.CATEGORIES]: number[];
}

export type ISProjectForm = { [key in keyof ISProjectFormData]: FormControl<ISProjectFormData[key]> };

export interface ISProject {
  project_id: number;
  project_name: string;
  client_slug: string;
  default_country: string;
  currency_codes: string[];
}

export interface ISProjectSetup extends ISProject {
  google_ads_accounts: string[];
  google_search_console_accounts: string[];
  categories_ids: number[];
}

export type ISAccountStatus = { status: ISAccountStatusEnum };
export type ISProjectGAdsAccount = Pick<ISGoogleAdsAccount, 'account_id' | 'name'> & ISAccountStatus;
export type ISProjectGSCAccount = { property: string } & ISAccountStatus;

export interface ISProjectDetails extends ISProject {
  google_ads_accounts: ISProjectGAdsAccount[];
  google_search_console_accounts: ISProjectGSCAccount[];
}

export type ISProjectDataRequest = Omit<ISProjectSetup, 'project_id' | 'currency_codes'>;

export interface ISProjectUI extends ISProject {
  client?: ISClient;
}

export type ISProjectsList = Pick<SharedListResponse<ISProject>, 'items'>;

export type ISProjectBase = Omit<ISProject, 'client_slug' | 'default_country' | 'currency_codes'>;
