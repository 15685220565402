export enum ISNumericMetricEnum {
  CLICKS_COVERAGE = 'clicks_coverage',
  COMBINED_IMPRESSIONS = 'combined_impressions',
  MAX_IMPRESSIONS = 'max_impressions',
  CONVERSIONS_VALUE = 'ppc_conversions_value',
  MONTHLY_SEARCHES = 'monthly_searches',
  PAID_IMPRESSIONS_SHARE = 'paid_impressions_share',
  MAX_PAID_IMPRESSIONS_SHARE = 'max_paid_impressions_share',
  PPC_CLICKS = 'ppc_clicks',
  PPC_CONVERSION_RATE = 'ppc_conversion_rate',
  PPC_CONVERSIONS = 'ppc_conversions',
  PPC_COST_SHARE = 'ppc_cost_share',
  PPC_CPA = 'ppc_cpa',
  PPC_CPC = 'ppc_cpc',
  PPC_CTR = 'ppc_ctr',
  PPC_IMPRESSIONS = 'ppc_impressions',
  PPC_ROAS = 'ppc_roas',
  PPC_SPEND = 'ppc_spend',
  SEO_CLICKS = 'seo_clicks',
  SEO_CTR = 'seo_ctr',
  SEO_IMPRESSIONS = 'seo_impressions',
  SEO_POSITION = 'seo_position',
}

export enum ISNonNumericMetricEnum {
  COMPETITION = 'competition',
  SEARCH_TERM_DIFFICULTY = 'search_term_difficulty',
  PPC_LANDING_PAGE_QUALITY_SCORE = 'ppc_landing_page_quality_score',
}

export const ISMetricEnum = { ...ISNumericMetricEnum, ...ISNonNumericMetricEnum };
