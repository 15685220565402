import { FormArray, FormControl, Validators } from '@angular/forms';

export class ISSharedInputParserHelper {
  static parseInputToArray(
    input: string,
    formArray: FormArray,
    minLength: number,
    maxLength: number,
    emitEvent = true,
  ): void {
    const entities: string[] = input
      .toLowerCase()
      .split(/[,\n]/)
      .map((k) => k.trim().replace(/\s\s+/g, ' '))
      .filter((v) => !!v && v.replaceAll(/\s/g, '').length)
      .sort();
    formArray.clear({ emitEvent: false });
    new Set(entities).forEach((entity: string) =>
      formArray.push(this.__getEntityControl(entity, minLength, maxLength), { emitEvent: false }),
    );
    formArray.updateValueAndValidity({ emitEvent });
  }

  private static __getEntityControl(entity: string, minLength: number, maxLength: number): FormControl<string> {
    return new FormControl<string>(entity, {
      nonNullable: true,
      validators: [Validators.minLength(minLength), Validators.maxLength(maxLength)],
    });
  }
}
